import React from 'react';
import './AboutHome.css';
import aboutHomeImage from '../../images/about-us.jpg';

function AboutHome() {
    return (
        <>
            <section className="about-home-section">
                <h1 className="about-home-heading">CS Indu Sisodia & Associates</h1>
                <div className="about-home-container">
                    <div className="about-text">
                        <h2>A Firm of Company Secretaries</h2>
                        <p>Our aim is to help the entrepreneur on the legal and regulatory requirements, and be a partner throughout the business lifecycle, offering support at every stage to ensure the business remains compliant and continually growing.</p>
                        <p>We take your vision as our mission and we do everything needed to fulfill it. We help entrepreneurs and small business owners, start and grow their business by providing quality and affordable services through internet.</p>
                        <div className="progress-bars">
                            <div className="progress-bar">
                                <span>Knowledge</span>
                                <div className="progress">
                                    <div className="progress-fill knowledge" style={{ width: '80%' }}>80%</div>
                                </div>
                            </div>
                            <div className="progress-bar">
                                <span>Experience</span>
                                <div className="progress">
                                    <div className="progress-fill experience" style={{ width: '75%' }}>75%</div>
                                </div>
                            </div>
                            <div className="progress-bar">
                                <span>Friendly Team</span>
                                <div className="progress">
                                    <div className="progress-fill friendly-team" style={{ width: '90%' }}>90%</div>
                                </div>
                            </div>
                            <div className="progress-bar">
                                <span>Absolute Confidentiality</span>
                                <div className="progress">
                                    <div className="progress-fill confidentiality" style={{ width: '100%' }}>100%</div>
                                </div>
                            </div>
                        </div>
                        <button className="read-more-btn">Read More</button>
                    </div>
                    <div className="about-image">
                        <img src={aboutHomeImage} alt="About Us Home" />
                    </div>
                </div>
            </section>
        </>
    );
}

export default AboutHome;
