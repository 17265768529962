import React, { useEffect } from "react";
import { FaUserCheck, FaHeadset } from 'react-icons/fa';
import { VscFileSubmodule } from "react-icons/vsc";
import { LiaBuromobelexperte } from "react-icons/lia";
import './Experience.css';

function Experience() {
    useEffect(() => {
        const cards = document.querySelectorAll('.experience-card');
        
        // Intersection Observer for animating cards on scroll
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate');
                    startCounting(entry.target.querySelector('.count'));
                }
            });
        }, { threshold: 0.5 });

        cards.forEach(card => observer.observe(card));
    }, []);

    // Counting function
    const startCounting = (element) => {
        const target = parseInt(element.getAttribute('data-count'));
        let count = 0;
        const increment = Math.ceil(target / 100); // Adjust speed by changing the divisor

        const updateCounter = () => {
            count += increment;
            if (count >= target) {
                element.textContent = target;
            } else {
                element.textContent = count;
                setTimeout(updateCounter, 40); // Adjust timing for smoother animation
            }
        };
        updateCounter();
    };

    const data = [
        { icon: <LiaBuromobelexperte />, number: 5, label: "Years Of Experience" },
        { icon: <FaUserCheck />, number: 252, label: "Happy Clients!" },
        { icon: <VscFileSubmodule />, number: 78, label: "Projects" },
        { icon: <FaHeadset />, number: 1820, label: "Hours Of Support" }
    ];

    return (
        <section className="experience-section" id="navbar">
            <div className="experience-container">
                {data.map((item, index) => (
                    <div key={index} className="experience-card">
                        <div className="icon-wrapper">
                            {item.icon}
                        </div>
                        <h3 className="count" data-count={item.number}>0</h3>
                        <p>{item.label}</p>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Experience;
