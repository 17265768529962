import React from 'react';
import './aboutuspage.css';
import bannerImage from '../images/About-Us-page-banner.png';
import financialImage from '../images/we-help-about-us.jpg'; // Replace with your actual image path
import whatWeDo from '../images/what-we-do.jpg'
// import { FaHeart, FaLightbulb, FaHandshake, FaUsers } from 'react-icons/fa';

import customerFocused from '../images/icons/Customer-Focused.png';
import userExperience from '../images/icons/User-Experience.png';
import earnTrust from '../images/icons/trust.png';
import riskManager from '../images/icons/riskmanagers.png';
import ownAndDeliver from '../images/icons/own-and-deliver.png';
import personalAdvisor from '../images/icons/personal-advisor.png';

const coreValuesData = [
    { icon: customerFocused, title: "Customer-Focused", description: "Registering a Company is quick, easy, and can be done in simple steps." },
    { icon: userExperience, title: "User Experience", description: "We maintain a simple managible system that is innovative & easy to understand." },
    { icon: earnTrust, title: "Earn Trust", description: "We seek input first and actively listen before taking action." },
    { icon: riskManager, title: "Risk Managers", description: "We manage your compliances and avoid heavy penalties." },
    { icon: ownAndDeliver, title: "Own & Deliver", description: "We learn from our mistakes and adapt with ease." },
    { icon: personalAdvisor, title: "Personal Advisor", description: "You get personal advisor you can reach anytime for all your queries." },
];


function AboutUsPage() {
    return (
        <div>
            {/* Banner Section */}
            <div className="about-banner">
                <div className="about-banner-image">
                    <img src={bannerImage} alt="About Us Illustration" />
                </div>
            </div>

            {/* Who We Are Section */}
            <div className="about-content">
                <h2>Who We Are</h2>
                <p>
                    Indu Sisodia & Associates is a firm of Practicing Company Secretaries based out at Noida. We focus on delivering quality services tailored to each client’s requirements. The firm is led by proprietor CS Indu Sisodia, a Company Secretary in Practice, with qualifications in B.Com, M.Com, and LLB.
                </p>
                <p>
                    We have handled and served diversified clients. Our core area of practice is Company Law and Secretarial Practices. Our aim is to grow alongside our clients, keeping them updated on amendments, updates, and due dates related to compliance.
                </p>
            </div>

            {/* We Help Business Master Their Financial Activities Section */}
            <div className="financial-activities-section">
                <img src={financialImage} alt="Financial Activities Background" />
                <div className="financial-activities-text">
                    <h1>We Help Business Master their Financial Activities</h1>
                </div>
            </div>

            {/* Understanding Your Finances Section */}
            <div className="finances-info-section">
                <h3>Understanding your Finances should be three things: Simple, Effortless and Affordable</h3>
                <p>
                    CS Indu simplifies small business finances. Our larger purpose expands beyond that we are here to help entrepreneurs understand what they want to do and where they want to go.
                </p>
            </div>

            {/* What We Do Section */}
            <div className="what-we-do-section">
                <h1>What We Do</h1>
                <div className="what-we-do-content">
                    <div className="what-we-do-text">
                        <h2>Consultation for Every Business</h2>
                        <p>
                            At CS Indu Sisodia & Associates, we offer personalized consultation services for businesses of all types, from startups to established enterprises. Our dedicated team provides expert guidance on legal, financial, and regulatory matters, supporting entrepreneurs at every stage of their journey. We help clients remain compliant, foster sustainable growth, and achieve their business vision with clarity and confidence.
                        </p>
                    </div>
                    <div className="what-we-do-image">
                        <img src={whatWeDo} alt="Consultation" />
                    </div>
                </div>
            </div>

            {/* Core Values Section */}
            <div className="core-values-section">
                <h2>Core Values Reflection in Everything We Do</h2>
                <div className="core-values-cards">
                    {coreValuesData.map((value, index) => (
                        <div key={index} className="core-value-card">
                            <img className="icon-size" src={value.icon} alt={`${value.title}`} />
                            <h3 className="core-value-title">{value.title}</h3>
                            <p className="core-value-description">{value.description}</p>
                        </div>
                    ))}
                </div>
            </div>


        </div>
    );
}

export default AboutUsPage;
