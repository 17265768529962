import React from 'react';
import './Company.css';

import companyPageBanner from '../images/company-page-banner.jpg';


function CompanyPage() {
    return (
        <>
            <div>

                {/* Banner Section */}
                <div className="company-banner-section">
                    <div className="company-banner-image">
                        <img src={companyPageBanner} alt="Company Banner Illustration" />
                    </div>
                </div>

                {/* Company Page Content Section */}
                <div className="company-page-section">
                    <div className="company-page-content">

                        <p>One must choose the best legal structure from permitted models to do business and formation of the company is one of the best way of doing business however, a company could broadly be a Private Limited Company or One Person Company or Limited Company or Section 8 Company. Based on the activity/requirement of the promoters, different types of companies can be incorporated under the Companies Act, 2013. </p>

                        <h2>Private company</h2>
                        <p> <strong> As per Section 2(68) of the companies Act 2013, A private company is defined as a ‘private company means a company having a minimum paid-up share capital as may be prescribed, and which by its articles: </strong> </p>

                        <p>
                            <ol>
                                <li> restricts the right to transfer its shares; </li>

                                <li> except in case of One Person Company, limits the number of its members to two hundred.</li>

                                <li> prohibits any invitation to the public to subscribe for any securities of the company.</li>
                            </ol>

                        </p>


                        <p>Most Startups and businesses in India with higher ambitions choose Private Limited Company as a suitable business structure. A Private Limited company enjoys the following benefits:</p>

                        <p><strong>Separate Legal Entity:</strong> A private limited company is said to be a separate legal entity. An entity means something which has a legal existence; therefore the company can sue and can also be sued under its name.</p>

                        <p><strong>Borrowing capacity:</strong> A private limited company enjoys the privileges of borrowing more funds than LLPs as it has more options for taking on debt. Not only are bank loans easy to obtain (relative to OPCs and LLPs), the option of issuing debentures and convertible debentures are always available. Even banks and other financial institutions welcome private limited companies better than partnership entities.</p>

                        <p><strong>Easy Exit:</strong> Private limited companies can be sold or transferred, either partially or in full, to another individual or entity without any disruption to the current business.</p>

                        <p><strong>Ability to sue and can be sued:</strong> To sue means to carry legal proceedings against a person, similarly just as one person can bring legal proceedings in its name against another in that person’s name, a company being a separate legal entity can sue and be sued in its name.</p>

                        <p><strong>Continuous Existence:</strong> The company’s existence remains unaffected by the death or resignation of any member.</p>

                        <p><strong>Complete Possession of Property:</strong> The shareholders cannot claim to be owners of the property of the company. The company itself is the owner.</p>

                        <p><strong>Dual relationship:</strong> A person in a Private Limited Company can be a shareholder/employee/director at the same time.</p>

                        <h2>Public company</h2>
                        <p>As per Section 2(71) of the Companies Act, a public company means “a company which is not a private company”.</p>

                        <p>A public limited is formed by a minimum of 7 (seven) persons with a minimum paid-up capital.</p>

                        <p>The company may get listed in the stock exchange and thereafter shares of the same are traded openly. There are more legal restrictions on this type of establishment than a Private Limited Company.</p>

                        <h3>A public limited company enjoys the following benefits:</h3>

                        <p><strong>Limited Liability:</strong> The liability of the shareholders is limited to their stake only. The business can be sued by not involving any shareholders.</p>

                        <p><strong>Number of Members:</strong> There is a minimum requirement of seven shareholders and can exceed any limitless number of members as its share capital can occupy.</p>

                        <p><strong>Continuous existence:</strong> The life span of the public limited company is not affected by the death of any member or shareholder.</p>

                        <p><strong>Huge Capital:</strong> Public Limited Company can relish an increased ability to raise capital through the stock market by issuing debentures and bonds from the public.</p>

                        <h2>One-Person Companies</h2>
                        <p>As per Section 2(62) of the Companies Act 2013, “one person company” means a company that has only one person as a member. This is a recent invention to facilitate entrepreneurs to own and manage companies alone.</p>

                        <p>All the shares can be owned by one person but there must be an additional nominee director to register this firm.</p>

                        <p><strong>Payment of Interest on any delay in payment:</strong> One Person Company can avail all benefits under the Micro, Small and Medium Enterprises Development Act 2006. One Person Company is either a small or medium entity, therefore in case of any delay of payment (receives payment after a specific period) to the buyer or the receiver they are entitled to receive interest thrice as much as the bank rate.</p>

                        <p><strong>Sole Owner:</strong> Only the owner is entitled to make business decisions and control the business without complying with the long processes and measures as adopted by few other companies.</p>

                        <p><strong>Additional opportunities:</strong> Through this structure, an individual can take a higher amount of risks in business without causing damage to personal assets.</p>

                        <h2>Nidhi Company</h2>
                        <p>Nidhi Company is a type of Non-Banking Financial Company (NBFC). It is formed to borrow and lend money to its members. It inculcates the habit of saving among its members and works on the principle of mutual benefit. These companies typically operate in the southern part of the country. Nidhi Company isn’t required to receive the license from Reserve Bank of India (RBI), hence it is easy to form. It is registered as a public company and should have “Nidhi Limited” as the last words of its name.</p>

                        <h3>Advantages of Nidhi Company:</h3>
                        <p>
                            <ul>
                                <li>These are highly localized single office institutions duly recognized by the government and governed
                                    primarily by the members, with no external involvement.</li>
                                <li>These companies offer easy financial loans to the financially middle and lower classes of people, with minimum formalities. The secured loans are available at rather reasonable rates for the purposes like house construction or repairs. Collateral in form of jewelry or mortgage of property is accepted.</li>
                                <li>These companies accept term deposits for timely returns.</li>
                                <li>These companies guarantee secured investments by virtue of rigid membership structure.</li>
                                <li>The loans and savings are facilitated by the minimum documentation and formalities.</li>

                            </ul>
                        </p>



                        <h3>Conditions to be fulfilled for getting ‘Nidhi’ status</h3>
                        <p>Within one year of its registration</p>
                        <strong>Nidhi Company should have minimum 200 members within one year from commencement</strong>
                        <p>Also, the net owned funds should be 10 lakh rupees or more. Net owned funds = Equity share capital + free reserves (-) accumulated losses (-) intangible assets</p>
                        <p>Unencumbered term deposits must be 10% or higher of the outstanding deposits</p>
                        <p>The ratio of net owned funds to deposits shouldn’t be more than 1:20</p>

                        <h2>Producer Company</h2>
                        <p>A producer company can be defined as a legally recognized body of farmers/ agriculturists with the aim to improve the standard of their living and ensure a good status of their available support, incomes and profitability.</p>
                        <h3>Silent Condition for Producer Companies:</h3>
                        <p>

                            <ol type="1">
                                <li>Only persons engaged in an activity connected with, or related to, primary produce can participate in the ownership.</li>
                                <li>The members have necessarily to be primary producers.</li>
                                <li>Termed as “Companies with Limited Liability” and the liability of the members will be limited to the amount, if any, unpaid on the shares.</li>
                                <li>Name of the company shall end with the words "Producer Company Limited".</li>
                                <li>On registration, the producer company shall become as if it is a Private Limited Company for the purpose of application of law and administration of the company</li>
                                <li>However, it shall comply with the specific provisions of part IXA.</li>
                                <li>The limit of maximum number of members is not applicable to these Companies.</li>
                            </ol>


                        </p>

                    </div>


                </div>



            </div>

        </>
    )
}

export default CompanyPage;