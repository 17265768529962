// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import AboutUsPage from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Services from './pages/Services';
import CompanyPage from './pages/Company';
import PrivateLimitedCompany from './pages/PrivateLimitedCompany';

function App() {
  return (
      <div className="App">
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/services" element={<Services />} />

          {/* Services Pages */}
          <Route path="/company-formation" element={<CompanyPage />} />
          <Route path="/private-limited-company" element={<PrivateLimitedCompany />} />
          {/* Add more routes as needed */}
        </Routes>

        <Footer />
      </div>
  );
}

export default App;
