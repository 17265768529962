import React from "react";
import "./BlogHome.css";

import ExtensionofAGM from '../../images/blog-post-Img/Extension-of-AGM.jpg';
import taxReturn from '../../images/blog-post-Img/MGT-9-Extract-of-Annual-Return.jpg';
import XBRLandIND from '../../images/blog-post-Img/CONNECTION-BETWEEN-XBRL-AND-IND-AS.jpg';

function BlogHome() {
    return (
        <section className="blog-section">
            <div className="blog-container">
                <h2 className="blog-title">Latest Blog Post</h2>
                <div className="blog-cards">

                    <div className="blog-card">
                        <img src={ExtensionofAGM} alt="AGM Meeting" className="blog-image"/>
                        <h3 className="blog-card-title">Extension of AGM for the F.Y. 2020-21</h3>
                        <p className="blog-card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <button className="blog-read-more">Read More</button>
                    </div>

                    <div className="blog-card">
                        <img src={taxReturn} alt="Tax Return" className="blog-image"/>
                        <h3 className="blog-card-title">MGT-9 (Extract of Annual Return) Applicability for F.Y. 2020-21</h3>
                        <p className="blog-card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <button className="blog-read-more">Read More</button>
                    </div>

                    <div className="blog-card">
                        <img src={XBRLandIND} alt="XBRL and IND AS" className="blog-image"/>
                        <h3 className="blog-card-title">Connection Between XBRL and IND AS</h3>
                        <p className="blog-card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                        <button className="blog-read-more">Read More</button>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default BlogHome;
