// src/pages/Contact.js
import React, { useEffect, useState } from "react";
import axios from 'axios';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

import './ContactUs.css'

import contactBanner from '../images/contect-us-page-banner.png';
// import contactBanner from '../images/contect-us-banner.jpg';

function ContactUs() {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        time: '',
        subject: '',
        message: '',
        userCaptcha: '',
    })

    const [captchaValue, setCaptchaValue] = useState(generateCaptcha());
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        if (success || error) {
            // Clear the message after 3 seconds
            const timer = setTimeout(() => {
                setSuccess('');
                setError('');
            }, 9000);

            // Clear the timer when the component unmounts
            return () => clearTimeout(timer);
        }
    }, [success, error]);

    function generateCaptcha() {
        return Math.random().toString(36).substring(2, 8); // generates a simple captcha string
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (formData.userCaptcha !== captchaValue) {
            setError('Incorrect CAPTCHA');
            return;
        }

        try {
            const response = await axios.post('http://localhost:5000/send-email', {
                ...formData,
                captchaValue
            });
            if (response.status === 200) { // Check if the status code is 200 or use response.status === 200
                setSuccess(response.data.message); // Display success message
                setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    time: '',
                    subject: '',
                    message: '',
                    userCaptcha: ''
                });
                setCaptchaValue(generateCaptcha()); // reset CAPTCHA
            }
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred while sending the message.');
        }
    };



    return (
        <>
            <div>

                {/* Banner Section */}
                <div className="contact-us-banner">
                    <div className="contact-banner-image">
                        <img src={contactBanner} alt="Contact Us Banner" />
                    </div>
                </div>

                {/* Where good conversations become great experiences */}
                <div className="great-experiences-section">
                    <h3>Where good conversations become great experiences</h3>
                    <p>
                        CS Indu Associates strives to provide the best service possible with every contact. To make customers happy, you need conversational experiences—connected communication across any business.
                    </p>
                </div>

                {/* Contact Information Section */}
                <div className="contact-info-section">
                    <h2>Lets talk about everything!</h2>
                    <p>Want to get in touch? We would love to hear from you.</p>
                    <div className="contact-cards">
                        <div className="contact-card">
                            <FaPhoneAlt className="contact-icon" />
                            <p>+91 859 553 4007</p>
                        </div>
                        <div className="contact-card">
                            <FaEnvelope className="contact-icon" />
                            <p>mail@csindu.in</p>
                        </div>
                        <div className="contact-card">
                            <FaMapMarkerAlt className="contact-icon" />
                            <p>B-74, Basement, Sector-2, Noida (U.P) - 201301</p>
                        </div>
                    </div>
                </div>

                {/* Contact Form Section */}
                <div className="contact-form-section">
                    <div className="contact-form-container">
                        <h2>Free Consultation</h2>

                        <form className="contact-us-form" onSubmit={handleSubmit}>
                            <div className="contact-us-form-row">
                                <input type="text" placeholder="Your Name" name="name" value={formData.name} onChange={handleInputChange} required />
                                <input type="text" placeholder="Your Phone" name="phone" value={formData.phone} onChange={handleInputChange} required />
                            </div>
                            <div className="contact-us-form-row">
                                <input type="email" placeholder="Your Email" name="email" value={formData.email} onChange={handleInputChange} required />
                                <select name="time" value={formData.time} onChange={handleInputChange} required>
                                    <option value="">Choose Time</option>
                                    <option value="9-11">9 AM to 11 AM</option>
                                    <option value="11-1">11 AM to 1 PM</option>
                                    <option value="2-4">2 PM to 4 PM</option>
                                    <option value="4-7">4 PM to 7 PM</option>
                                </select>
                            </div>
                            <div className="contact-us-form-row">
                                <input type="text" placeholder="Subject" name="subject" value={formData.subject} onChange={handleInputChange} required />
                                <div className="contact-us-captcha-row">
                                    <input type="text" value={captchaValue} readOnly />
                                    <input type="text" placeholder="Enter Captcha Value" name="userCaptcha" value={formData.userCaptcha} onChange={handleInputChange} required />
                                </div>
                            </div>
                            <textarea placeholder="Your Message" name='message' value={formData.message} onChange={handleInputChange} required></textarea>
                            {error && <p className="message error">{error}</p>}
                            {success && <p className="message success">{success}</p>}
                            <button type="submit">SUBMIT</button>
                        </form>
                    </div>
                </div>

                {/* Contact Form Map Section */}
                <div className="contact-us-map-section">
                    <iframe
                        title="Google Map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5249.304849051002!2d77.31146133089874!3d28.585052265278396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5ecfbdaa8f3%3A0x99fcebe37ec60718!2sIndu%20Sisodia%20%26%20Associates%20-%20CS%20in%20Noida!5e0!3m2!1sen!2sus!4v1635063399192!5m2!1sen!2sus"
                        width="100%"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>


            </div>
        </>
    );
}

export default ContactUs;
