import React, { useState } from "react";
import { FaQuoteLeft, FaQuoteRight, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "./Reviews.css";

import saurabh from '../../images/reviews/saurabh-shukla.jpg';
import rajive from '../../images/reviews/rajive-mehta-web.jpg';
import manish from '../../images/reviews/manish-jain-web.jpg';

const reviews = [
    {
        image: saurabh,
        name: "Saurabh Shukla",
        position: "General Manager",
        content: "CS Indu has a good understanding of Startups and their specific issues. Valuation is a core complex for any startup. I am glad with the support when they handle all respective things related to business and suggested all the suggested complex thing to avoid them for future problems. I am highly satisfied."
    },
    {
        image: rajive,
        name: "Rajive Mehta",
        position: "Founder",
        content: "A fully professional Company Secretarial firm with them i am connected now. CS Indu is a very knowledgeable and experienced in this field and having excellent support for their work in this field. I would like to recommend CS Indu for any company secretarial assignment."
    },
    {
        image: manish,
        name: "Manish Jain",
        position: "CEO & Founder",
        content: "I received excellent support and services during my company registration process. I would like to give special thanks to Indua for their valuable support & time. I will definitely recommend CS Indu for the company registration process."
    },
];

function Reviews() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextReview = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    };

    const prevReview = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length);
    };

    return (
        <section className="reviews-section" id="reviews">
            <div className="reviews-container">
                {/* Position arrows outside main review content */}
                <FaArrowLeft onClick={prevReview} className="arrow-icon arrow-left" />
                <FaArrowRight onClick={nextReview} className="arrow-icon arrow-right" />

                <div className="review-details">
                    <img src={reviews[currentIndex].image} alt={reviews[currentIndex].name} className="review-image" />
                    <h3>{reviews[currentIndex].name}</h3>
                    <p className="position">{reviews[currentIndex].position}</p>
                </div>

                <div className="review-content">
                    <FaQuoteLeft className="quote-icon left" />
                    <p>{reviews[currentIndex].content}</p>
                    <FaQuoteRight className="quote-icon right" />
                </div>

                <div className="review-indicators">
                    {reviews.map((_, index) => (
                        <span
                            key={index}
                            className={`indicator ${index === currentIndex ? "active" : ""}`}
                        ></span>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Reviews;
