// src/pages/Home.js
import React from 'react';
import HeroBanner from '../components/HeroBanner/HeroBanner';
import Features from '../components/Features/Features';
import AboutHome from '../components/AboutHome/AboutHome';
import ServicesHome from '../components/ServicesHome/ServicesHome';
import Experience from '../components/Experience/Experience';
import Faq from '../components/Faq/Faq';
import Reviews from '../components/Reviews/Reviews';
import ContactHome from '../components/Contact/ContactHome';
import BlogHome from '../components/BlogHome/BlogHome';

function Home() {
    return (
        <div>
            <HeroBanner />
            <Features />
            <AboutHome />
            <ServicesHome />
            <Experience />
            <Faq />
            <Reviews />
            <ContactHome />
            <BlogHome />
        </div>
    );
}

export default Home;
