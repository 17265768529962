import React from 'react';
import './PrivateLimitedCompany.css';

import privateLimitedPageBanner from '../images/company-page-banner.jpg';


function PrivateLimitedCompany() {
    return (
        <>
            <div>

                {/* Banner Section */}
                <div className="private-limited-banner-section">
                    <div className="private-limited-banner-image">
                        <img src={privateLimitedPageBanner} alt="Private Limited Banner Illustration" />
                    </div>
                </div>

                {/* Company Page Content Section */}
                <div className="private-limited-page-section">
                    <div className="private-limited-page-content">

                        <h2>Private Limited Company</h2>

                        <p> <strong> As per Section 2(68) of the companies Act 2013, A private company is defined as
                            a ‘private company means a company having a minimum paid-up share capital as may be
                            prescribed, and which by its articles: </strong> </p>

                        <p>
                            <ol type="1">
                                <li>restricts the right to transfer its shares.</li>

                                <li>except in case of One Person Company, limits the number of its members to two
                                    hundred.</li>

                                <li>prohibits any invitation to the public to subscribe for any securities of the
                                    company.</li>


                            </ol>
                        </p>

                        <p>Most Startups and businesses in India with higher ambitions choose Private Limited Company
                            as a suitable business structure. A Private Limited company enjoys the following benefits:</p>
                        
                            <p><strong>Separate Legal Entity:</strong> A private limited company is said to be a separate
                            legal entity. An entity means something which has a legal existence; therefore the company can sue and can also be sued
                            under its name.</p>
                        
                            <p><strong>Borrowing capacity:</strong> A private limited company enjoys the privileges of
                            borrowing more funds than LLPs as it has more options for taking on debt. Not only are bank loans easy to obtain (relative
                            to OPCs and LLPs), the option of issuing debentures and convertible debentures are always available.
                            Even banks and other financial institutions welcome private limited companies better than partnership
                            entities.</p>
                        
                            <p><strong>Easy Exit:</strong> Private limited companies can be sold or transferred, either
                            partially or in full, to another individual or entity without any disruption to the current business.</p>
                        
                            <p><strong>Ability to sue and can be sued:</strong> To sue means to carry legal proceedings
                            against a person, similarly just as one person can bring legal proceedings in its name against another in that person’s
                            name, a company being a separate legal entity can sue and be sued in its name.</p>
                        
                            <p><strong>Continuous Existence:</strong> The company’s existence remains unaffected by the
                            death or resignation of any member.</p>
                        
                            <p><strong>Complete Possession of Property:</strong> The shareholders cannot claim to be
                            owners of the property of the company. The company itself is the owner.</p>
                        
                            <p><strong>Dual relationship:</strong> A person in a Private Limited Company can be a
                            shareholder/employee/director at the same time.</p>

                    </div>


                </div>



            </div>

        </>
    )
}

export default PrivateLimitedCompany;