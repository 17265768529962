import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./HeroBanner.css";

import bannerImage1 from "../../images/CSIndu-Home-Page-Banner-2.jpg";
import bannerImage2 from "../../images/company-registration-2.jpg";
import bannerImage3 from "../../images/firm-registration.jpg";
import bannerImage4 from "../../images/GST-registration.jpg";

function HeroBanner() {
    const [currentSlide, setCurrentSlide] = useState(0);
    const banners = [
        bannerImage1,
        bannerImage2,
        bannerImage3,
        bannerImage4,
    ];

    {/* const handleNextSlide = () => {
        if (currentSlide < banners.length - 1) {
            setCurrentSlide(currentSlide + 1);
        }
    }; */}

    const handleNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % banners.length);
    };

   {/*  const handlePrevSlide = () => {
            if (currentSlide > 0) {
            setCurrentSlide(currentSlide - 1);
        }
    }; */}

    const handlePrevSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? banners.length - 1 : prevSlide - 1
        );
    };

    return (
        <section className="hero-banner-section">
            <div className="hero-banner-container">
                <img
                    key={currentSlide}
                    src={banners[currentSlide]}
                    alt={`Banner ${currentSlide + 1}`}
                    className="banner-image"
                />
                <button className="arrow left-arrow" onClick={handlePrevSlide}>
                    <FaChevronLeft />
                </button>
                <button className="arrow right-arrow" onClick={handleNextSlide}>
                    <FaChevronRight />
                </button>
            </div>
        </section>
    );
}

export default HeroBanner;
