import React, { useEffect, useState } from "react";
import axios from 'axios';
import "./ContactHome.css";

function ContactHome() {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        email: '',
        time: '',
        subject: '',
        message: '',
        userCaptcha: '',
    })

    const [captchaValue, setCaptchaValue] = useState(generateCaptcha());
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        if (success || error) {
            // Clear the message after 3 seconds
            const timer = setTimeout(() => {
                setSuccess('');
                setError('');
            }, 9000);

            // Clear the timer when the component unmounts
            return () => clearTimeout(timer);
        }
    }, [success, error]);

    function generateCaptcha() {
        return Math.random().toString(36).substring(2, 8); // generates a simple captcha string
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (formData.userCaptcha !== captchaValue) {
            setError('Incorrect CAPTCHA');
            return;
        }

        try {
            const response = await axios.post('http://localhost:5000/send-email', {
                ...formData,
                captchaValue
            });
            if (response.status === 200) { // Check if the status code is 200 or use response.status === 200
                setSuccess(response.data.message); // Display success message
                setFormData({
                    name: '',
                    phone: '',
                    email: '',
                    time: '',
                    subject: '',
                    message: '',
                    userCaptcha: ''
                });
                setCaptchaValue(generateCaptcha()); // reset CAPTCHA
            }
        } catch (err) {
            setError(err.response?.data?.error || 'An error occurred while sending the message.');
        }
    };

    return (
        <section className="contact-section">
            <div className="contact-container-home">
                <h2>Free Consultation</h2>
                
                <form className="contact-form" onSubmit={handleSubmit}>
                    <div className="form-row">
                        <input type="text" placeholder="Your Name" name="name" value={formData.name} onChange={handleInputChange} required />
                        <input type="text" placeholder="Your Phone" name="phone" value={formData.phone} onChange={handleInputChange} required />
                    </div>
                    <div className="form-row">
                        <input type="email" placeholder="Your Email" name="email" value={formData.email} onChange={handleInputChange} required />
                        <select name="time" value={formData.time} onChange={handleInputChange}required>
                            <option value="">Choose Time</option>
                            <option value="9-11">9 AM to 11 AM</option>
                            <option value="11-1">11 AM to 1 PM</option>
                            <option value="2-4">2 PM to 4 PM</option>
                            <option value="4-7">4 PM to 7 PM</option>
                        </select>
                    </div>
                    <div className="form-row">
                        <input type="text" placeholder="Subject" name="subject" value={formData.subject} onChange={handleInputChange} required />
                        <div className="captcha-row">
                            <input type="text" value={captchaValue} readOnly />
                            <input type="text" placeholder="Enter Captcha Value" name="userCaptcha" value={formData.userCaptcha} onChange={handleInputChange} required />
                        </div>
                    </div>
                    <textarea placeholder="Your Message" name='message' value={formData.message} onChange={handleInputChange} required></textarea>
                    {error && <p className="message error">{error}</p>}
                    {success && <p className="message success">{success}</p>}
                    <button type="submit">SUBMIT</button>
                </form>
            </div>
        </section>
    );
}

export default ContactHome;
