import React from "react";
import "./Footer.css";
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from "react-icons/fa";

function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-section">
            <div className="footer-container">
                <div className="footer-column">
                    <h3>CS Indu Sisodiya</h3>
                    <p>
                        Indu Sisodiya is M.com, an Associate member of the Institute of Company Secretaries of India. She is practicing as a Company Secretary under the firm name M/s. Indu Sisodiya & Associates (“ISA”), Company Secretaries.
                    </p>
                </div>
                <div className="footer-column">
                    <h3>Useful Links</h3>
                    <ul>
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about-us">About</Link></li>
                        <li><Link to="/contact-us">Contact</Link></li>
                        <li><Link to="/blog">Blog</Link></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <h3 className="services-list-heading">Our Services</h3>
                    <ul className="services-list">
                        <li><Link to="/company-formation">Company</Link></li>
                        <li><Link to="/services/ngo">NGO</Link></li>
                        <li><Link to="/services/audits">Audits</Link></li>
                        <li><Link to="/services/registration-certification">Registration & Certification</Link></li>
                        <li><Link to="/services/restructuring">Restructuring</Link></li>
                        <li><Link to="/services/firm">Firm</Link></li>
                        <li><Link to="/services/gst">GST</Link></li>
                        <li><Link to="/services/income-tax">Income Tax</Link></li>
                        <li><Link to="/services/liaisoning">Liaisoning</Link></li>
                        <li><Link to="/services/advisory">Advisory</Link></li>
                        <li><Link to="/services/secretarial">Secretarial</Link></li>
                    </ul>
                </div>
                <div className="footer-column social-links">
                    <h3>Socials</h3>
                    <ul>
                        <li>
                            <a href="https://www.facebook.com/csindusisodia/" target="_blank" rel="noopener noreferrer">
                                <FaFacebookF /> Facebook
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/csindusisodia" target="_blank" rel="noopener noreferrer">
                                <FaTwitter /> Twitter
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/csindusisodia/" target="_blank" rel="noopener noreferrer">
                                <FaInstagram /> Instagram
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/cs-indu-sisodia/" target="_blank" rel="noopener noreferrer">
                                <FaLinkedinIn /> LinkedIn
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="footer-bottom">
                <p>© {currentYear} - CS Indu Sisodiya. All Rights Reserved. &nbsp;&nbsp;&nbsp;&nbsp; <span>Website Developed by <a href="#PervezAlam" target="_blank" rel="noopener noreferrer"> Pervez Alam </a></span></p>
            </div>
        </footer>
    );
}

export default Footer;
