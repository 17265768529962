import React from "react";
import { FaThumbsUp, FaTrophy } from 'react-icons/fa'; // import icons
import { FaCodeCompare } from "react-icons/fa6";
import { MdOutlineWorkspacePremium } from "react-icons/md";
import './Features.css';

function Features() {
    return (
        <>
            <section className="features-section">
                <div className="features-container">
                    <div className="feature-card">
                        <div className="icon-circle">
                            <FaThumbsUp className="feature-icon" />
                        </div>
                        <h3>The right advice</h3>
                        <p>Our team are experts in matching you with the right provider.</p>
                    </div>
                    <div className="feature-card">
                        <div className="icon-circle">
                            <FaTrophy className="feature-icon" />
                        </div>
                        <h3>Recognised for excellence</h3>
                        <p>We have been awarded for our high rate of customer satisfaction.</p>
                    </div>
                    <div className="feature-card">
                        <div className="icon-circle">
                            <FaCodeCompare className="feature-icon" />
                        </div>
                        <h3>Compare the best</h3>
                        <p>We only compare market leaders with a reputation for service quality.</p>
                    </div>
                    <div className="feature-card">
                        <div className="icon-circle">
                            <MdOutlineWorkspacePremium className="feature-icon" />
                        </div>
                        <h3>Premium Services</h3>
                        <p>We only compare market leaders with a reputation for service quality.</p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Features;
