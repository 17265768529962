import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import './Faq.css';


function Faq() {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqData = [
        {
            question: "How can I register a company?",
            answer: "Incorporating a company through Simplified Proforma for Incorporating Company electronically (SPICe -INC-32), with eMoA (INC-33), eAOA (INC-34), is the default option and most companies are required to be incorporated through SPICe only."
        },
        {
            question: "How to start an LLP?",
            answer: "To register an Indian LLP, you need to first apply for a Designated Partner Identification Number (DPIN)..."
        },
        {
            question: "Which companies qualify for CSR under the Companies Act, 2013?",
            answer: "A company satisfying any of the following criteria during the immediately preceding financial year is required to comply with CSR provisions specified under section 135(1) of the Companies Act, 2013..."
        },
        {
            question: "What is e Form SPICe+?",
            answer: "SPICe+ is a part of various initiatives undertaken by the Government of India towards Ease of Doing Business (EODB)..."
        },
        {
            question: "What is Part A of webform SPICe+ and can the same be filed separately?",
            answer: "SPICe+ Part A represents the section wherein all details with respect to name reservation for a new company have to be entered..."
        },
        {
            question: "What are the services offered in Part B of SPICe+?",
            answer: `Part B of SPICe+ offers following services viz.
            a) Incorporation;
            b) DIN allotment;
            c) Mandatory issue of PAN;
            d) Mandatory issue of TAN;
            e) Mandatory issue of EPFO registration;
            f) Mandatory issue of ESIC registration;
            g) Mandatory issue of Profession Tax registration, only for companies to be registered in Maharashtra, Karnataka, and West Bengal;
            h) Mandatory opening of bank account for the company;
            i) Allotment of GSTIN (optional, if applied for); and
            j) Allotment of Shops and Establishment Registration Number (Only for Delhi location).
        `
        }
    ];

    const toggleFAQ = index => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section className="faq-section">
            <div className="faq-container">
                <h2>Frequently Asked Questions</h2>
                {faqData.map((faq, index) => (
                    <div
                        key={index}
                        className={`faq-item ${activeIndex === index ? 'active' : ''}`}
                        onClick={() => toggleFAQ(index)}
                    >
                        <div className="faq-question">
                            {faq.question}
                            {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
                        </div>
                        {activeIndex === index && (
                            <div className="faq-answer">
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Faq;
