import React, { useState } from "react";
import './Navbar.css';
// import { FaChevronDown } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

import csLogo from '../../images/CS-indu-website-logo-2.png';

const navDropDownList = [
    {
        name: "COMPANY",
        link: "/company-formation",
        subItems: [
            { name: "PRIVATE LIMITED COMPANY", link: "/private-limited-company" },
            { name: "PUBLIC LIMITED COMPANY", link: "#public-limited" },
            { name: "ONE PERSON COMPANY", link: "#one-person" },
            { name: "NIDHI COMPANY", link: "#nidhi" },
            { name: "PRODUCER COMPANY", link: "#producer" },
            { name: "SECTION 8 COMPANY", link: "#section-8" }
        ]
    },
    {
        name: "FIRM",
        link: "#firm",
        subItems: [
            { name: "PROPRIETORSHIP", link: "#proprietorship" },
            { name: "PARTNERSHIP", link: "#partnership" },
            { name: "LIMITED LIABILITY PARTNERSHIP", link: "#llp" }
        ]
    },
    {
        name: "NGO",
        link: "#ngo",
        subItems: [
            { name: "society", link: "#society" },
            { name: "trust", link: "#trust" },
            { name: "section 8 company", link: "#section-8-company" }
        ]
    },
    {
        name: "GST",
        link: "#gst",
        subItems: [
            { name: "gst registration", link: "#gst-registration" },
            { name: "gst return filing", link: "#gst-return-filing" },
            { name: "section 8 company", link: "#section-8-company" }
        ]
    },
    {
        name: "AUDITS",
        link: "#audits",
        subItems: [
            { name: "secretarial audit", link: "#secretarial-audit" },
            { name: "search and status report", link: "#search-and-status-report" },
            { name: "internal audit", link: "#internal-audit" },
            { name: "due deligence report", link: "#due-deligence-report" },
            { name: "certification of annual return", link: "#certification-of-annual-return" },
            { name: "management audit", link: "#management-audit" },
        ]
    },
    {
        name: "INCOME TAX",
        link: "#income-tax",
        subItems: [
            { name: "income tax return filing", link: "#income-tax-return-filing" },
            { name: "advance tax payment", link: "#advance-tax-payment" },
            { name: "tds return filing", link: "#tds-return-filing" }
        ]
    },
    {
        name: "REGISTRATION & CERTIFICATION",
        link: "#registration-certification",
        subItems: [
            { name: "fssai registration", link: "#fssai-registration" },
            { name: "pf registration", link: "#pf-registration" },
            { name: "trade mark registration", link: "#trade-mark-registration" },
            { name: "shop and establishment registration", link: "#shop-and-establishment-registration" },
            { name: "iso certification", link: "#iso-certification" },
            { name: "msme registration", link: "#msme-registration" },
            { name: "charge registration", link: "#charge-registration" },
        ]
    },
    {
        name: "LIASONING",
        link: "#liasoning",
        subItems: [
            { name: "registrar of companies(roc)", link: "#registrar-of-companies" },
            { name: "regional director(rd)", link: "#regional-director" },
            { name: "central government(MCA)", link: "#central-government" },
            { name: "reserve bank of india(rbi)", link: "#reserve-bank-of-india" },
            { name: "income tax office(ito)", link: "#income-tax-office" },
            { name: "revenue department(sdm)", link: "#revenue-department" },
            { name: "securities exchange board of india(sebi)", link: "#securities-exchange-board-of-india" },
            { name: "gst-office", link: "#gst-office" },
        ]
    },
    {
        name: "ADVISORY",
        link: "#advisory",
        subItems: [
            { name: "companies law", link: "#companies-law" },
            { name: "limited liability partnership act", link: "#limited-liability-partnership-act" },
            { name: "tax law", link: "#tax-law" },
            { name: "industrail and labour law", link: "#industrail-and-labour-law" },
            { name: "foreign direct investment(fdi)", link: "#foreign-direct-investment" },
            { name: "external commercial borrowing(ecb)", link: "#external-commercial-borrowing" },
        ]
    },
    { name: "RESTRUCTURING", link: "#re-structuring" },
    {
        name: "SECRETARIAL",
        link: "#secretarial",
        subItems: [
            { name: "maintenance of statutory records", link: "#maintenance-of-statutory-records" },
            { name: "public/rights/bonus issue of shares", link: "#public-rights-bonus-issue-of-shares" },
        ]
    }
];



function Navbar() {


    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [openSubMenu, setOpenSubMenu] = useState(null); // New state for individual sub-menus

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
        setOpenSubMenu(null); // Close all sub-menus when mobile menu is toggled
    };

    const toggleSubMenu = (index) => {
        setOpenSubMenu(openSubMenu === index ? null : index); // Toggle specific sub-menu
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
        setOpenSubMenu(null);
    };

    return (
        <nav className="navbar-section" id="navbar">
            <div className="navbar-container">
                <div className="navbar-logo">
                    <Link to="/" onClick={closeMobileMenu}>
                        <img src={csLogo} alt="CS Indu Website Logo" />
                    </Link>
                </div>

                <div className="hamburger-icon" onClick={toggleMobileMenu}>
                    {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
                </div>

                <div className={`navbar-links ${isMobileMenuOpen ? 'active' : ''}`}>
                    <Link to="/" onClick={closeMobileMenu}>Home</Link>

                    <div className="dropdown">
                        <Link to="/services" className="dropdown-link">Services<span>▼</span></Link>
                        <div className="dropdown-menu">
                            {navDropDownList.map((item, index) => (
                                <div key={index} className="dropdown-item">
                                    <span onClick={() => toggleSubMenu(index)}>
                                        {item.name} 
                                    </span>

                                    {item.subItems && openSubMenu === index && (
                                        <div className="sub-dropdown">
                                            {item.subItems.map((subItem, subIndex) => (
                                                <Link key={subIndex} to={subItem.link} onClick={closeMobileMenu}>
                                                    {subItem.name}
                                                </Link>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>

                    <Link to="/about-us" onClick={closeMobileMenu}>About</Link>
                    <Link to="#blog" onClick={closeMobileMenu}>Blog</Link>
                    <Link to="/contact-us" onClick={closeMobileMenu}>Contact</Link>
                </div>

                <div className="navbar-search">
                    <input type="text" placeholder="Search" className="search-input" />
                    <button className="search-button">Search</button>
                </div>
            </div>
        </nav>
    );
}


export default Navbar;











