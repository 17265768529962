import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from React Router

import './ServicesHome.css';


// Import All Icons
import company from '../../images/icons/company.png'; 
import firm from '../../images/icons/firm.png';
import ngo from '../../images/icons/ngo2.png';
import gst from '../../images/icons/gst.png';
import audits from '../../images/icons/audit.png';
import incometax from '../../images/icons/incometax.png';
import registration from '../../images/icons/registration.png';
import liasoning from '../../images/icons/liasoning.png';
import advisory from '../../images/icons/advisory.png';
import restructuring  from '../../images/icons/RESTRUCTURING.png';
import secretarial from '../../images/icons/SECRETARIAL.png';

function ServicesHome() {
    const services = [
        { icon: company, title: "Company", description: "Registering a Company is quick, easy, and can be done in simple steps.", link: "/services/company"  },

        { icon: firm, title: "Firm", description: "Firm is the simplest form of doing business with least compliances.", link: "/services/company"  },

        { icon: ngo, title: "NGO", description: "Quick registration of NGOs for the betterment of society.", link: "/services/company"  },

        { icon: gst, title: "GST", description: "Get a secure GST number and file your returns timely.", link: "/services/company"  },

        { icon: audits, title: "Audits", description: "Audits seek to identify areas of inefficiency and make corrective recommendations.", link: "/services/company"  },

        { icon: incometax, title: "Income Tax", description: "Now is the good time to file your ITR. SOONER THE BETTER.", link: "/services/company"  },

        { icon: registration, title: "Registration & Certification", description: "Get a wide array of registration in various laws with ease.", link: "/services/company"  },

        { icon: liasoning, title: "Liasoning", description: "Liasoning with authorities to ensure that the “requirements” as set out are met.", link: "/services/company"  },

        { icon: advisory, title: "Advisory", description: "Get comprehensive secretarial advisory services with utmost dedication.", link: "/services/company"  },

        { icon: restructuring, title: "Restructuring", description: "Restructuring means improving structures of your business to ensure efficient operation.", link: "/services/company"  },

        { icon: secretarial, title: "Secretarial", description: "Complete service provider for all your secretarial needs.", link: "/services/company"  },

    ];

    return (
        <section className="services-section">
            <div className="services-container"> 
                <h2>Our Services</h2>
                <div className="services-grid">
                    {services.map((service, index) => (
                        <div key={index} className="service-card">
                            <img src={service.icon} alt={`${service.title}`} className="service-icon" />
                            <h3>{service.title}</h3>
                            <p>{service.description}</p>
                            <Link to={service.link}>
                                <button>Read More</button>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default ServicesHome;
