import React from 'react';
import './Services.css';

import servicesPageBanner from '../images/company-page-banner.jpg';


function Services() {
    return (
        <>
            <div>

                {/* Banner Section */}
                <div className="services-banner-section">
                    <div className="services-banner-image">
                        <img src={servicesPageBanner} alt="Private Limited Banner Illustration" />
                    </div>
                </div>

                {/* Company Page Content Section */}
                <div className="services-page-section">
                    <div className="services-page-content">

                        <h2>CS Indu Sisodia & Associates Services</h2>

                        <p>
                            At <strong>CS Indu Sisodia & Associates</strong>, we are committed to helping entrepreneurs navigate the legal and regulatory landscape, supporting them at every step of their business journey. Our expert services ensure that your business remains compliant, resilient, and primed for growth. Led by experienced Company Secretary CS Indu Sisodia (B.Com, M.Com, LLB), our firm is known for delivering tailored solutions that empower businesses to thrive with clarity and confidence.
                        </p>


                        <h3>Company Registration & Structuring</h3>
                        <p>Starting your business with the right structure is crucial. We offer comprehensive registration services to suit your needs:</p>

                        <p>
                            <ul>
                                <li>Private Limited Company</li>
                                <li>Public Limited Company</li>
                                <li>One Person Company</li>
                                <li>Nidhi Company</li>
                                <li>Producer Company</li>
                                <li>Section 8 Company</li>

                            </ul>
                        </p>

                        <h3>Firm Registration</h3>
                        <strong>For entrepreneurs who prefer alternative business models, we offer registration for:</strong>




                        <p>
                            <ul>
                                <li>Proprietorship</li>
                                <li>Partnership</li>
                                <li>Limited Liability Partnership (LLP)</li>

                            </ul>
                        </p>

                        <h3>NGO Registration</h3>
                        <strong>Set up a non-profit with ease:</strong>

                        <p>
                            <ul>
                                <li>Society</li>
                                <li>Trust</li>
                                <li>Section 8 Company</li>

                            </ul>
                        </p>

                        <h3>Taxation & Compliance</h3>
                        <strong>Keeping up with taxes can be overwhelming. Our tax services are designed to simplify your financial responsibilities:</strong>

                        <p>
                            <ul>
                                <li>GST Registration & Filing</li>
                                <li>Income Tax Return Filing</li>
                                <li>Advance Tax Payments</li>
                                <li>TDS Return Filing</li>

                            </ul>
                        </p>

                        <h3>Audits & Reports</h3>
                        <strong>Stay on top of your company’s compliance status with our audit services:</strong>

                        <p>
                            <ul>
                                <li>Secretarial Audit</li>
                                <li>Search and Status Report</li>
                                <li>Internal Audit</li>
                                <li>Due Diligence Report</li>
                                <li>Management Audit</li>

                            </ul>
                        </p>


                        <h3>Registration & Certification</h3>
                        <strong>Get all the necessary certifications and registrations your business needs to operate smoothly:</strong>

                        <p>
                            <ul>
                                <li>FSSAI Registration</li>
                                <li>ESI & PF Registration</li>
                                <li>Trademark Registration</li>
                                <li>Shop and Establishment Registration</li>
                                <li>ISO Certification</li>
                                <li>MSME Registration</li>

                            </ul>
                        </p>


                        <h3>Advisory Services</h3>
                        <strong>We provide expert advice on various legal matters:</strong>

                        <p>
                            <ul>
                                <li>Companies Law</li>
                                <li>Tax Laws</li>
                                <li>Industrial and Labor Law</li>
                                <li>Foreign Direct Investment (FDI)</li>
                                <li>External Commercial Borrowing (ECB)</li>

                            </ul>
                        </p>


                        <h3>Why Choose Us?</h3>
                        <strong>Indu Sisodia & Associates brings you:</strong>

                        <strong>Personalized Service – </strong>Every business is unique, and we tailor our services to meet your specific needs.

                        <strong>Expert Guidance – </strong>Led by an experienced Company Secretary, our team is well-versed in the latest regulations.

                        <strong>End-to-End Support – </strong>From business inception to compliance maintenance, we are with you every step of the way.

                        <br></br>
                        <br></br>
                        
                        <h3>Contact Us Today</h3>
                        Ready to take your business to the next level? Let us handle the complexities so you can focus on growth. Reach out to CS Indu Sisodia & Associates for a consultation today and experience the peace of mind that comes with professional, reliable guidance.



                    </div>


                </div>



            </div>

        </>
    )
}

export default Services;